<template>
  <div class="flex-col page">
    <div class="flex-col justify-start self-stretch  section relative">
      <div class="tabs">
        <img class="image pos" src="../../img/3f16e645b14dc2fbd279b7069d3101bd.png" />
        <img class="image_2 pos_2" src="../../img/c7739287396e42eb3563ca8077cb6970.png" />
        <span class="font_2 pos_6" :class="tabsIndex == 0 ? 'font_2_choose' : ''" @click="tabsClick(0)">关于我们</span>
        <span class="font_2 pos_7" :class="tabsIndex == 1 ? 'font_2_choose' : ''" @click="tabsClick(1)">产品</span>
        <span class="font_2 pos_8" :class="tabsIndex == 2 ? 'font_2_choose' : ''" @click="tabsClick(2)">服务案例</span>
        <span class="font_2 pos_9" :class="tabsIndex == 3 ? 'font_2_choose' : ''" @click="tabsClick(3)">技术与保障</span>
        <span class="font_2 pos_10" :class="tabsIndex == 4 ? 'font_2_choose' : ''" @click="tabsClick(4)">合作</span>
        <span class="text_3 pos_11">PIN XUN KE JI</span>
      </div>
    </div>
    <div class="flex-col justify-start section">
      <div class="flex-col section_2">
        <div class="flex-col items-start mt-245">
          <div class="text_8">我们不断创新和探索</div>
          <div class="font text_9 mt-37">只为企业品牌重塑</div>
        </div>
      </div>
    </div>
    <div class="flex-col group_3">
      <div class=" ">
        <div class=" text_10 ">服务案例</div>
        <div class="flex-row justify-between items-center self-stretch group_28 group_4">
          <div class="flex-row">
            <div class="flex-col justify-start items-center button_3"><span class="font_14">精品推荐</span></div>
            <div class="flex-col justify-start items-center text-wrapper_2 ml-9">
              <span class="font_13 text_20">政务网站</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_2 ml-9">
              <span class="font_13 text_20">品牌网站</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_2 ml-9">
              <span class="font_13 text_20">手机网站</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_2 ml-9">
              <span class="font_13 text_20">系统开发</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_2 ml-9">
              <span class="font_13 text_20">响应式网站</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_2 ml-9">
              <span class="font_13 text_20">微信小程序</span>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="flex-col justify-start relative grid-item" v-for="(item, index) in currentPageData" :key="index">
            <div class="flex-col relative section_18">
              <div class="flex-row justify-between self-stretch">
                <span class="font_4">{{ item.name }}</span>
                <img class="image_19 image_20" :src="item.type" />
              </div>
              <span class="self-start font_15 text_21">{{ item.desc }}</span>
              <div class="flex-row justify-between items-center self-stretch group_31">
                <span class="font_19">查看更多</span>
                <img class="image_9 image_23" src="../../img/80c8d792cb9c4753eadbef23d3fc3de2.png" />
              </div>
            </div>
            <img class="image_18 pos_21" :src="item.src" />
          </div>
        </div>
      </div>
      <div class="flex-row justify-center group_17">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-size="pagesize" :total="list.length">
        </el-pagination>
      </div>
    </div>
    <div class="flex-col group_18">
      <div class="flex-row justify-between section_9">
        <div class="flex-col self-center group_19">
          <img class="self-center image_41" src="../../img/25bc506b1a2d38ef4092bcfd5b3c150f.png" />
          <span class="self-center font_3 text_69">河南品讯网络科技有限公司</span>
          <span class="self-center text_73">0379-61118888</span>
          <div class="self-stretch group_23">
            <span class="font_14 text_77">
              地址：河南省洛阳市老城区青年创业大厦24层
            </span>
          </div>
        </div>
        <div class="self-center section_10"></div>
        <div class="flex-col items-start self-center">
          <span class="font_13">产品体系</span>
          <span class="font_14 text_66">友推云·电商</span>
          <span class="font_14 text_70">友推云·名片</span>
          <span class="font_14 text_74">友推云·会展</span>
          <span class="font_14 text_80">友推云·微官网</span>
        </div>
        <div class="flex-col items-start self-center">
          <span class="font_13 text_64">解决方案</span>
          <span class="font_14 text_67">新零售</span>
          <span class="font_14 text_71">知识付费</span>
          <span class="font_14 text_75">智慧街道</span>
          <span class="font_14 text_81">线上展会</span>
        </div>
        <div class="flex-col items-start self-start group_20">
          <span class="font_13 text_65">服务体系</span>
          <span class="font_14 text_68">软件开发</span>
          <span class="font_14 text_72">信息化咨询</span>
          <span class="font_14 text_76">企业上云</span>
        </div>
        <div class="self-center section_10"></div>
        <div class="flex-col self-center">
          <div class="flex-row">
            <div class="flex-col justify-start relative group_21">
              <div class="group_22"></div>
              <img class="image_43 pos_72" src="../../img/b9138baec0eb743ec366219cb10c477c.png" />
              <img class="image_42 pos_70" src="../../img/c71d4904444746a4da7224d79a321bd2.png" />
            </div>
            <div class="ml-24 flex-col justify-start relative group_21">
              <div class="group_22"></div>
              <div class="flex-col justify-start items-center image-wrapper pos_73">
                <img class="image_44" src="../../img/6a905240878593e4810d0748bb252371.png" />
              </div>
              <img class="image_42 pos_71" src="../../img/c71d4904444746a4da7224d79a321bd2.png" />
            </div>
          </div>
          <div class="mt-26 flex-row group_24">
            <span class="font_15 text_78">微信小程序</span>
            <span class="ml-66 font_15 text_79">微信公众号</span>
          </div>
        </div>
      </div>
      <div class="flex-col justify-start items-center text-wrapper_5">
        <span class="font_6 text_83">Copyright © 2022 河南品讯网络科技有限公司 版权所有豫ICP备18009817号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tabsIndex: 2,
      list: [{
        name: "龙门文创院",
        desc: '文创服务管理网站',
        src: require('../../img/4c76237e0b84dc7d69802b3898cb2e02.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "运输机场跑道表面状况检测系统",
        desc: '机场服务管理系统',
        src: require('../../img/7d4e97fcc9b1c4fc8f7fc98168d0338f.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "中原国际文化旅游产业博览会",
        desc: '文化旅游服务平台',
        src: require('../../img/5032971cd0dd3c954ee80a3e51c0207a.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "洛阳民族宗教事务局",
        desc: '政务服务网站',
        src: require('../../img/3fca848aa9dac8aecc9049cccc9e45e8.png'),
        type: require('../../img/16d6b7268e392000c120de523cd73f5a.png')
      }, {
        name: "先导家具",
        desc: '家具服务网站',
        src: require('../../img/8af65bf0f67ce2ce2155e7a0f7e560a9.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "风向标",
        desc: '商标服务平台',
        src: require('../../img/79870657bd9229c93795b8898870d756.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "品讯 · 好商会",
        desc: '商协会组织平台',
        src: require('../../img/032d5989f84c46f6d006e676922cada3.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "品讯 · 亥时学习平台",
        desc: '企业学习成长平台',
        src: require('../../img/e9d1866596dd7aea68fa9d241346ba17.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "谜局 · 剧本杀",
        desc: '剧本杀管理平台',
        src: require('../../img/30a9d519db77957b11e61e823614cd4a.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "智慧人大",
        desc: '人大服务管理平台',
        src: require('../../img/0a7a4b380c61aec518012a4b72d5341e.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "智慧养老系统",
        desc: '养老服务管理平台',
        src: require('../../img/0ae5d43b2ec2a97c892a27825771d184.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "远程踏勘",
        desc: '视频踏勘',
        src: require('../../img/b38e464b4f7e00d7dfb54f244f75746c.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "乡村振兴综合服务平台",
        desc: '居民考核管理平台',
        src: require('../../img/965d810f0409e3ebe3d875af761f2265.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "运输机场跑道表面状况检测系统",
        desc: '机场服务管理系统',
        src: require('../../img/c3537cbceb94cc2f92202d4df9e5722e.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "商圈儿",
        desc: '活动发布管理平台',
        src: require('../../img/6db7467c59cee309ccd306443ef00558.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      },
      {
        name: "新契约录入",
        desc: '保单录入管理平台',
        src: require('../../img/2ff6f4d9c7750b46bc49915af54c8fca.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "紫品轩",
        desc: '品牌直营电商平台',
        src: require('../../img/a4c21f7eb01fc4130b02451c1961d196.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "无感加油",
        desc: '汽车加油服务平台',
        src: require('../../img/2fb67c620e4eb56f2520194775deb069.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "河南省青联委员履职考核系统",
        desc: '履职考核系统管理平台',
        src: require('../../img/71b867f1cd00d390af812d0214add645.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "龙门园区基础工程产业联盟综合服务平台",
        desc: '招工服务平台',
        src: require('../../img/4096966b0cb46af119e50ef92deb73cc.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "孟津田园",
        desc: 'SAAS商城平台',
        src: require('../../img/db1fa468fed3d060a68c0fe5dc6022f7.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "明珠路双报到",
        desc: '社区服务管理平台',
        src: require('../../img/212af80a8b30b4bcd0171f5353a0d2d9.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "慧享崇文双报到",
        desc: '社区服务管理平台',
        src: require('../../img/e3e8ee3afb16f822ef4b543111a6068b.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "沙河路双报到",
        desc: '社区服务管理系统',
        src: require('../../img/c5c7ee3190b0184377825b9a631ec811.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "特拉维斯奥林匹亚",
        desc: '赛事、电商平台',
        src: require('../../img/4a5aad11a457ccef83d14e6e5aa708c5.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "KK同城",
        desc: '广告推广平台',
        src: require('../../img/ede007958a32102353c31284b3f4d6bc.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "生活品鉴局",
        desc: '电商自营平台',
        src: require('../../img/dc5a0341e75ab0c26b39556e9528d3ad.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "亥时美术馆",
        desc: '画作展示管理平台',
        src: require('../../img/45e70d36ebddef73ce4e39d2e100475f.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "友推云 · 电商",
        desc: '电商平台',
        src: require('../../img/3b4f3c0658adb7315c593d5494fd26f9.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "友推云 · 会展",
        desc: '会展平台',
        src: require('../../img/a5c6b34d4cd7be51ad38724535c401eb.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "惠锋母婴坊",
        desc: '母婴电商平台',
        src: require('../../img/79c18fa8d167e8d6fb3727cddb4335a0.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "5151服装店",
        desc: '电商平台',
        src: require('../../img/71fbc22bf17e5c078d70c771471579d6.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "伊川君安物业管理系统",
        desc: '物业管理平台',
        src: require('../../img/9c11e421ec016787f18545a634f63894.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "娥女当家",
        desc: '公益资讯平台',
        src: require('../../img/f9443bc71cf898e17be27fc72616408b.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      }, {
        name: "洛阳 新联会",
        desc: '社交组织服务平台',
        src: require('../../img/16182b9549420d07c13b80f9e6126a45.png'),
        type: require('../../img/6a83a74f7819548d5b99c14611b8082b.png')
      }, {
        name: "锦润商城",
        desc: '电商平台',
        src: require('../../img/ce55d563a74976ab6ff1817108c6418d.png'),
        type: require('../../img/a2753329abb6050338ac550fcf1d7014.png')
      },
      ],
      currentPage: 1,
      currentIndex: "",
      pagesize: 9,
      currentPageData: []
    };
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getpage()
  },
  methods: {
    getpage() {
      const startIndex = (this.currentPage - 1) * this.pagesize;
      // 分页的末尾下标
      const endIndex = startIndex + this.pagesize;
      this.currentPageData = this.list.slice(startIndex, endIndex);
    },

    handleCurrentChange(val) {
      this.currentPage = val
      this.getpage()
    },
    productDetail(e) {
      window.open(e, "_blank");
    },
    tabsClick(e) {
      if (e != 2) {
        this.$router.push('/')
        this.$router.push({ path: '/', query: { tabsIndex: e } });
      }
    }
  },
};
</script>

<style scoped lang="scss">
.ml-15 {
  margin-left: 15px;
}

.mt-3 {
  margin-top: 3px;
}

.ml-130 {
  margin-left: 130px;
}

.mt-245 {
  margin-top: 245px;
}

.mt-37 {
  margin-top: 37px;
}

.mt-17 {
  margin-top: 17px;
}

.ml-23 {
  margin-left: 23px;
}

.ml-21 {
  margin-left: 21px;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .tabs {
    height: 98px;
    // background: rgba(35, 66, 110, 0.5019607843);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 20px;
    z-index: 999;
  }

  .section {
    background-image: url('../../img/4dbe275032170c9131053319f3be76a7.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .section_2 {
      padding: 123px 135px 289px;
      background-color: #23426e80;

      .text_8 {
        color: #ffffff;
        font-size: 120px;
        font-family: Source Han Sans CN;
        font-weight: 700;
        line-height: 68px;
        letter-spacing: 12px;
        text-shadow: 0px 7px #38383854;
        margin-bottom: 50px;
      }

      .font {
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
      }

      .text_9 {
        letter-spacing: 2.5px;
      }
    }

    .image {
      width: 53px;
      height: 54px;
    }

    .pos {
      position: absolute;
      left: 240px;
      top: 21px;
    }

    .image_2 {
      width: 94px;
      height: 23px;
    }

    .pos_2 {
      position: absolute;
      left: 308px;
      top: 29px;
    }

    .font_2 {
      font-size: 16px;
      font-family: PingFang;
      line-height: 16px;
      color: #ffffff;
      cursor: pointer;
    }

    .font_2_choose {
      padding-bottom: 8px;
      border-bottom: 3px solid #2c89fd;
    }

    .font_2:hover {
      color: #2c89fd;
    }


    .pos_6 {
      position: absolute;
      right: 575px;
      top: 39px;
    }

    .pos_7 {
      position: absolute;
      right: 488px;
      top: 39px;
    }

    .pos_8 {
      position: absolute;
      right: 359px;
      top: 39px;
    }

    .pos_9 {
      position: absolute;
      right: 225px;
      top: 39px;
    }

    .pos_10 {
      position: absolute;
      right: 147px;
      top: 39px;
    }


    .pos_4 {
      position: absolute;
      right: 246px;
      top: 37px;
    }

    .text_3 {
      color: #ffffff;
      font-size: 14px;
      font-family: PingFang;
      line-height: 12px;
    }

    .pos_11 {
      position: absolute;
      left: 308px;
      top: 55px;
    }

    .pos_13 {
      position: absolute;
      left: 220px;
      top: 250px;
    }

    .text-wrapper {
      padding: 14px 0;
      border-radius: 24px;
      width: 180px;
      border-left: solid 1px #f8faff;
      border-right: solid 1px #f8faff;
      border-top: solid 1px #f8faff;
      border-bottom: solid 1px #f8faff;

      .text_6 {
        color: #f8faff;
        font-weight: 700;
        line-height: 19px;
      }
    }

  }

  .grid {
    margin-top: 26px;
    width: 1450.5px;
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 0;
    column-gap: 12.5px;

    .grid-item {
      padding: 50px 0 10px;
      width: 476.5px;

      .section_18 {
        margin-right: 10px;
        padding: 245px 16px 0;
        background-color: #ffffff;
        box-shadow: 0px 9px 16px #3838381a;
        width: 466px;
        border-left: solid 1px #dedede;
        border-right: solid 1px #dedede;
        border-top: solid 1px #dedede;
        border-bottom: solid 1px #dedede;

        .image_20 {
          margin-right: 5px;
        }

        .text_21 {
          margin-top: 15px;
        }
      }

      .image_18 {
        width: 466px;
        height: 263px;
      }

      .pos_21 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .section_19 {
        margin-right: 10px;
        padding: 245px 16px 0;
        background-color: #ffffff;
        width: 466px;
        border-left: solid 1px #dedede;
        border-right: solid 1px #dedede;
        border-top: solid 1px #dedede;
        border-bottom: solid 1px #dedede;

        .image_21 {
          margin-right: 4px;
          width: 47px;
          height: 18px;
        }

        .text_22 {
          margin-top: 16px;
          line-height: 15px;
        }

        .group_30 {
          padding: 0 2px;

          .image_22 {
            margin-right: 3px;
          }
        }

        .text_23 {
          margin-top: 15px;
        }

        .image_26 {
          margin-right: 4px;
          width: 21px;
          height: 18px;
        }

        .text_26 {
          margin-top: 16px;
          line-height: 15px;
        }

        .font_20 {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          line-height: 16px;
          color: #333333;
        }

        .text_24 {
          line-height: 17px;
        }

        .image_27 {
          margin-right: 4px;
        }

        .text_27 {
          margin-top: 16px;
          line-height: 15px;
        }

        .text_25 {
          line-height: 17px;
        }

        .image_28 {
          margin-right: 5px;
        }

        .text_28 {
          margin-top: 16px;
          line-height: 15px;
        }
      }

      .group_31:hover {
        color: #2c89fd;
      }

      .group_31 {
        margin-top: 21px;
        padding: 16px 0;
        border-top: solid 1px #dedede;
        cursor: pointer;

        .image_23 {
          margin-right: 4px;
        }

        .image_24 {
          margin-right: 4px;
        }

        .image_25 {
          margin-right: 5px;
        }

        .image_29 {
          margin-right: 4px;
        }

        .image_30 {
          margin-right: 4px;
        }

        .image_31 {
          margin-right: 5px;
        }
      }

      .pos_22 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .image_19 {
        height: 18px;
      }

      .pos_23 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .pos_24 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .pos_25 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }

      .pos_26 {
        position: absolute;
        left: 0;
        right: 9.5px;
        top: 10px;
      }
    }
  }

  .group_28 {
    margin-top: 36px;

    .button_3 {
      padding: 6px 0;
      background-color: #2c89fd;
      border-radius: 14px;
      width: 108px;
      cursor: pointer;
    }

    .text-wrapper_2:hover {
      color: #2c89fd;
    }

    .text-wrapper_2 {
      cursor: pointer;
      padding: 6px 0;
      background-color: #ffffff;
      border-radius: 14px;
      width: 108px;
      border-left: solid 1px #999999;
      border-right: solid 1px #999999;
      border-top: solid 1px #999999;
      border-bottom: solid 1px #999999;
      margin-left: 10px;

      .text_20 {
        line-height: 15px;

      }
    }
  }

  .group_3 {
    margin-top: 60px;
    padding: 0 236px;

    .group_4 {
      padding-bottom: 34px;
      border-bottom: solid 1px #e9e8e4;

      .font_14 {
        color: #fff;
      }
    }

    .text_10 {
      color: #333333;
      font-size: 42px;
      font-family: Source Han Sans CN;
      line-height: 40px;
    }

    .group_17 {
      margin-top: 58px;
    }
  }

  .group_18 {
    margin-top: 75px;

    .section_9 {
      padding: 58px 240px 32px;
      background-color: #222222;

      .group_19 {
        width: 274px;

        .image_41 {
          width: 55px;
          height: 55px;
        }

        .text_69 {
          margin-top: 14px;
          color: #ffffff;
          font-weight: 700;
          line-height: 21px;
        }

        .text_73 {
          margin-top: 18px;
          color: #ffffff;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          font-weight: 700;
          line-height: 24px;
        }

        .group_23 {
          margin-top: 36px;
          line-height: 20px;

          .text_77 {
            line-height: 20px;
          }
        }
      }

      .section_10 {
        background-color: #ffffff1f;
        width: 1px;
        height: 200px;
      }

      .font_13 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        letter-spacing: 1.5px;
        line-height: 16px;
        font-weight: 700;
        color: #ffffff;
      }

      .font_14 {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        letter-spacing: 1.5px;
        line-height: 16px;
        color: #ffffff;
      }

      .text_66 {
        margin-top: 32px;
      }

      .text_70 {
        margin-top: 23px;
      }

      .text_74 {
        margin-top: 22px;
      }

      .text_80 {
        margin-top: 23px;
      }

      .text_64 {
        line-height: 17px;
      }

      .text_67 {
        margin-top: 33px;
        line-height: 15px;
      }

      .text_71 {
        margin-top: 23px;
      }

      .text_75 {
        margin-top: 23px;
        line-height: 15px;
      }

      .text_81 {
        margin-top: 24px;
        line-height: 15px;
      }

      .group_20 {
        margin-top: 15px;

        .text_65 {
          line-height: 17px;
        }

        .text_68 {
          margin-top: 33px;
          line-height: 15px;
        }

        .text_72 {
          margin-top: 24px;
          line-height: 15px;
        }

        .text_76 {
          margin-top: 23px;
          line-height: 15px;
        }
      }

      .group_21 {
        width: 130px;

        .group_22 {
          opacity: 0.4;
          height: 130px;
          border-left: dashed 1px #ffffff;
          border-right: dashed 1px #ffffff;
          border-top: dashed 1px #ffffff;
          border-bottom: dashed 1px #ffffff;
        }

        .image_43 {
          width: 106px;
          height: 106px;
        }

        .pos_72 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .image_42 {
          width: 130px;
          height: 130px;
        }

        .pos_70 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .image-wrapper {
          padding: 41px 0;
          background-image: url('../../img/4b64faa5aab9acfe49510fdf544c4ae3.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 106px;

          .image_44 {
            width: 24px;
            height: 24px;
          }
        }

        .pos_73 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .pos_71 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .group_24 {
        padding: 0 20px;

        .font_15 {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          letter-spacing: 1.5px;
          line-height: 16px;
          color: #dfdfdf;
        }

        .text_78 {
          line-height: 15px;
        }

        .text_79 {
          line-height: 15px;
        }
      }
    }

    .text-wrapper_5 {
      padding: 24px 0;
      background-color: #1b1b1b;

      .text_83 {
        line-height: 17px;
      }
    }
  }

  .font_3 {
    font-size: 20px;
    font-family: MicrosoftYaHei;
    letter-spacing: 2px;
    line-height: 20px;
    color: #333333;
  }

  .font_6 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    line-height: 16px;
    color: #999999;
  }
}
</style>